import { Link } from 'gatsby';
import React, { createRef, useEffect } from 'react';

import Arrow from '../../images/Arrow-2.svg';
import { cn } from '../../utils';
import { animateOnOtherElementScreenEntry } from '../../utils/animations';
import Icon from '../Icon/Icon';
import * as styles from './LinkWithIcon.module.scss';
import ILinkWithIcon from './LinkWithIcon.type';

const LinkWithIcon = (props: ILinkWithIcon) => {
    const border = createRef<HTMLDivElement>();
    const text = createRef<HTMLDivElement>();
    const icon = createRef<HTMLImageElement>();

    useEffect(() => {
        if (
            icon.current &&
            text.current &&
            border.current &&
            props.animateFunctions &&
            props.animationContainer
        ) {
            animateOnOtherElementScreenEntry(
                props.animateFunctions.animateIcon,
                icon.current,
                props.animationContainer
            );
            animateOnOtherElementScreenEntry(
                props.animateFunctions.animateText,
                text.current,
                props.animationContainer
            );
            animateOnOtherElementScreenEntry(
                props.animateFunctions.animateBorder,
                border.current,
                props.animationContainer
            );
        }
    }, [props.animationContainer]);

    return (
        <Link className={styles.link} to={props.link.url}>
            <div
                className={styles.iconWrapper}
                ref={border}
                style={{ transform: props.animateFunctions ? "scale(0)" : "" }}
            >
                <div className={styles.iconBorder}>
                    <div
                        ref={icon}
                        style={{
                            opacity: props.animateFunctions ? "0" : "",
                        }}
                    >
                        <Icon {...props.icon} className={styles.icon} width={41} height={41}/>
                    </div>
                </div>
            </div>
            <div
                ref={text}
                className={cn(styles.bottom, "text-large")}
                style={{ opacity: props.animateFunctions ? "0" : "" }}
            >
                {props.link.title} <img src={Arrow} alt="arrow" />
            </div>
        </Link>
    );
};

export default LinkWithIcon;
