import React, { createRef, useEffect, useState } from 'react';

import LinkWithIcon from '../../components/LinkWithIcon/LinkWithIcon';
import { cn } from '../../utils';
import { animateOnOtherElementScreenEntry } from '../../utils/animations';
import * as styles from './CallToActionLinks.module.scss';
import ICallToActionLinks from './CallToActionLinks.type';

const CallToActionLinks = (props: ICallToActionLinks) => {
    const [sectionEl, setSectionEl] = useState<HTMLDivElement | null>();
    const sectionRef = createRef<HTMLDivElement>();
    const lineRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (lineRef.current && sectionRef.current)
            animateOnOtherElementScreenEntry(
                (el: HTMLElement) => {
                    el.style.transition = animationTime + "s";
                    el.style.transform = "scaleX(1)";
                    setTimeout(() => {
                        el.style.transition = "";
                    }, animationTime * 1000);
                },
                lineRef.current,
                sectionRef.current
            );
        setSectionEl(sectionRef.current);
    }, []);

    return (
        <section ref={sectionRef} className={styles.section}>
            <div className={cn(styles.container, "container")}>
                <div
                    ref={lineRef}
                    className={styles.line}
                    style={{ transform: "scaleX(0)" }}
                ></div>
                <ul className={styles.list}>
                    {props.links.map((item, i) => (
                        <li key={item.link.title}>
                            <LinkWithIcon
                                {...item}
                                animationContainer={sectionEl}
                                animateFunctions={{
                                    animateText: animateText(i),
                                    animateBorder: animateBorder(i),
                                    animateIcon: animateText(i),
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    );
};

export default CallToActionLinks;

const animationTime = 2.5;
const elemsTransition = 0.3;
const animLatency = 0.3;

const animateText = (i: number) => (el: HTMLElement) => {
    setTimeout(() => {
        el.style.transition = elemsTransition + "s";
        el.style.opacity = "1";
        setTimeout(() => {
            el.style.transition = "";
        }, elemsTransition * 1000);
    }, ((animationTime * (1 + i)) / 6 + animLatency) * 1000);
};

const animateBorder = (i: number) => (el: HTMLElement) => {
    setTimeout(() => {
        el.style.transition = elemsTransition + "s";
        el.style.transform = "scale(1)";
        setTimeout(() => {
            el.style.transition = "";
        }, elemsTransition * 1000);
    }, ((animationTime * (1 + i)) / 6) * 1000);
};
